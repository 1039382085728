/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Input from '@material-ui/icons/Input';
import Referrals from "views/Referrals/Referrals.js";
import ReadyMeals from "views/ReadyMeals/ReadyMeals.js";
import Group from '@material-ui/icons/Group';
import Parcels from "views/Parcels/Parcels.js";
import AssessmentIcon from '@material-ui/icons/Assessment';
import Reports from "views/Reports/Reports.js";
import FurtherAssessments from "views/FurtherAssessments/FurtherAssessments.js";
import Guests from "views/Guests/Guests.js";
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import Users from "views/Users/Users.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import Euro from '@material-ui/icons/Euro';
import Fastfood from '@material-ui/icons/Fastfood';
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_BRENT_HUBS', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "قائمة الجدول",
    icon: Person,
    component: Users,
    layout: "/admin",
    roles: ['ROLE_ADMIN']
  },
  {
    path: "/guests",
    name: "Guests",
    rtlName: "قائمة الجدول",
    icon: Group,
    component: Guests,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/referrals",
    name: "Referrals",
    rtlName: "قائمة الجدول",
    icon: Input,
    component: Referrals,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/readymeals",
    name: "Ready Meals",
    rtlName: "قائمة الجدول",
    icon: Fastfood,
    component: ReadyMeals,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_BRENT_HUBS', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/parcels",
    name: "Parcels",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: Parcels,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_BRENT_HUBS', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/furtherassessment",
    name: "Further Assessment",
    rtlName: "قائمة الجدول",
    icon: Euro,
    component: FurtherAssessments,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA', 'ROLE_BRENT_HUBS', 'ROLE_CASH_PILOT_ADMINISTRATOR']
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "قائمة الجدول",
    icon: AssessmentIcon,
    component: Reports,
    layout: "/admin",
    roles: ['ROLE_ADMIN', 'ROLE_SUFRA']
  }
];

export default dashboardRoutes;
